import React, {useState} from "react";
import Button from "@mui/material/Button";
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import Alert from '@mui/material/Alert';

function LoginBox(props) {
    const [name, setName] = useState("");
    const [pass, setPass] = useState("");
    const [error, setError] = useState(false);
    function login(inName, inPass) {
        setError(false);
        if((inName.toLowerCase() === "mklein@furhaven.com" || inName.toLowerCase() === "lpurpura@furhaven.com") && inPass === "FurhavenAdmin") {
            props.set(true);
        }
        else {
            setError(true);
        }
    }

    return (
        <Box display="flex" flexDirection="column" style={{margin:"auto", marginTop:"80px", width:"20%", zIndex:45}} >
            {error ? <Alert severity="error">Invalid email/password</Alert> : null}
            <OutlinedInput style={{marginBottom:"10px"}} placeholder="Email" variant="contained" onChange={(e)=>setName(e.target.value)} />
            <OutlinedInput style={{marginBottom:"10px"}} placeholder="Password" variant="contained" type="password" onChange={(e)=>setPass(e.target.value)} />
            <Button variant="contained" color="primary" onClick={()=>login(name, pass)}>Log In</Button>
        </Box>
    )
}


export default LoginBox;