import React from "react";
import Button from "@mui/material/Button";
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import MenuIcon from '@mui/icons-material/Menu';

function NavBar(props) {


    return (
        <Box display="flex" flexDirection="column" style={{marginTop:"auto", marginBottom:"auto", width:"10%", zIndex:45}} sx={{width: "50%"}} >
            <Button size="large" style={{color:"black", zIndex:50, position:"absolute", top:"12px"}} onClick={()=>props.setChecked(!props.checked)}><MenuIcon style={{fontSize:"30px", color:"white", position:"absolute", top:"-4px"}} /></Button>
            <Collapse orientation="horizontal" in={props.checked} style={{zIndex:"10"}}>
                <Box display="flex" flexDirection="column" style={{height:"100vh", width:"100%", paddingLeft:"10px", paddingRight:"10px", background:"#90BFA9", borderRight:"1px dashed grey"}} >
                    <Box style={{width:"100%", height:"1px", background:"black", marginTop:"10px", marginBottom:"10px"}} />
                    <Button disabled={props.loading} style={{marginTop:"40px", zIndex:50, color:"black"}} onClick={()=>props.changePage("warehouseToday")}>Today's Builds</Button>
                    <Box style={{width:"100%", height:"1px", background:"black", marginTop:"10px", marginBottom:"10px"}} />
                    <Button disabled={props.loading} style={{zIndex:50, color:"black"}} onClick={()=>props.changePage("workersToday")}>Today's Worker Detail</Button>
                    <Box style={{width:"100%", height:"1px", background:"black", marginTop:"10px", marginBottom:"10px"}} />
                    <Button disabled={props.loading} style={{zIndex:50, color:"black"}} onClick={()=>props.changePage("warehouseHistoric")}>Historic Builds</Button>
                    <Box style={{width:"100%", height:"1px", background:"black", marginTop:"10px", marginBottom:"10px"}} />
                    <Button disabled={props.loading} style={{zIndex:50, color:"black"}} onClick={()=>props.changePage("workersHistoric")}>Historic Worker Detail</Button>
                    <Box style={{width:"100%", height:"1px", background:"black", marginTop:"10px", marginBottom:"10px"}} />
                    <Button disabled={props.loading} style={{zIndex:50, color:"black"}} onClick={()=>props.changePage("workersBreaks")}>Worker Break Detail</Button>
                    <Box style={{width:"100%", height:"1px", background:"black", marginTop:"10px", marginBottom:"10px"}} />
                    <Button disabled={props.loading} style={{zIndex:50, color:"black"}} onClick={()=>props.changePage("efficiencyReport")}>Efficiency Report</Button>
                    <Box style={{width:"100%", height:"1px", background:"black", marginTop:"10px", marginBottom:"10px"}} />
                    <Button disabled={props.loading} style={{zIndex:50, color:"black"}} onClick={()=>props.changePage("unbuilt")}>Unbuilt Orders</Button>
                    <Box style={{width:"100%", height:"1px", background:"black", marginTop:"10px", marginBottom:"10px"}} />
                    <Button disabled={props.loading} style={{zIndex:50, color:"black"}} onClick={()=>props.changePage("report")}>Resend Reports</Button>
                    <Box style={{width:"100%", height:"1px", background:"black", marginTop:"10px", marginBottom:"10px"}} />
                </Box>
            </Collapse>
        </Box>
    )
}


export default NavBar;