import React, {useState} from "react";
import Button from "@mui/material/Button";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Box from '@mui/material/Box';
import OutlinedInput from "@mui/material/OutlinedInput";
import Moment from "moment-timezone";
import Alert from "@mui/material/Alert";
import Axios from "axios"

function Report(props) {
    const [date, setDate] = useState(null);
    const [wh, setWh] = useState(null);
    const [group, setGroup] = useState(null);
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState("");

    let setError = props.setError;


    function downloadReport(outDate, outWh, outGroup) {
        setLoading(true);
        let reqDate = Moment(outDate).tz("America/New_York").format('YYYY-MM-DD');
        let req = {"date": reqDate, "wh":outWh, "line": outGroup};
        setError("");
        setSuccess("");
        reportReq(req).then(function(result) {
            setSuccess("Report sent.");
            setLoading(false);
        })
        .catch(function(err) {
            console.log(err);
            setError("Invalid date.");
            setLoading(false);
        })
    }

    function reportReq(req) {
        return new Promise((resolve, reject) => {
            let url = "https://api.commerce-ims.com/v1/operations/resendReport";
            Axios.put(url, req, {mode: 'no-cors', headers:{company_id:"Furhaven"}}).then(() => {resolve()}).catch((error) => {console.log(error); reject(error)});
        })
    }

    return (
        <Box display="flex" flexDirection="column" style={{width:"100%", position:"absolute", top:"90px", zIndex:45}} >
            {success ? <Alert style={{width:"50%", margin:"auto", marginBottom:"10px"}} severity="success">{success}</Alert> : null}
            <Box display="flex" flexDirection="column" style={{margin:"auto"}}>
                <OutlinedInput onChange={(e)=>setDate(e.target.value)} type="Date" />
                <Select style={{marginTop:"20px", marginBottom:"20px"}} onChange={(e)=>setWh(e.target.value)} placeholder="Warehouse" label="Warehouse">
                    <MenuItem value="KY">Kentucky</MenuItem>
                    <MenuItem value="PA">Pennsylvania</MenuItem>
                    <MenuItem value="TX">Texas</MenuItem>
                </Select>
                {wh === "KY" ? <Select onChange={(e)=>setGroup(e.target.value)} placeholder="Group" label="Group">
                    <MenuItem value="1">Group 1</MenuItem>
                    <MenuItem value="2">Group 2</MenuItem>
                    <MenuItem value="3">Group 3</MenuItem>
                    <MenuItem value="4">Group 4</MenuItem>
                    <MenuItem value="5">Group 5</MenuItem>
                    <MenuItem value="6">Group 6</MenuItem>
                    <MenuItem value="7">Group 7</MenuItem>
                    <MenuItem value="8">Group 8</MenuItem>
                    <MenuItem value="9">Smalls Table</MenuItem>
                    <MenuItem value="10">Night Shift Group 1</MenuItem>
                    <MenuItem value="11">Night Shift Group 2</MenuItem>
                    <MenuItem value="12">Night Shift Group 3</MenuItem>
                </Select> : null}
                {wh === "PA" ? <Select onChange={(e)=>setGroup(e.target.value)} label="Group">
                    <MenuItem value="1">Group 1</MenuItem>
                    <MenuItem value="2">Group 2</MenuItem>
                    <MenuItem value="3">Group 3</MenuItem>
                    <MenuItem value="4">Group 4</MenuItem>
                </Select>: null}
                {wh === "TX" ? <Select onChange={(e)=>setGroup(e.target.value)} label="Group">
                    <MenuItem value="1">Group 1</MenuItem>
                    <MenuItem value="2">Group 2</MenuItem>
                    <MenuItem value="3">Group 3</MenuItem>
                    <MenuItem value="4">Group 4</MenuItem>
                    <MenuItem value="5">Group 5</MenuItem>
                </Select>: null}
            
                <Button style={{marginTop:"20px"}} disabled={!date || !wh || !group || loading} onClick={()=>downloadReport(date, wh, group)} variant="contained" color="success">Send</Button>
            </Box>
        </Box>
    )
}


export default Report;