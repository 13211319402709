import React from "react";

import Box from '@mui/material/Box';
import {DataGrid} from '@mui/x-data-grid';


function ViewTable(props) {



    return (
        <Box display="flex" flexDirection="column" style={{width:"100%", zIndex:1}}>
            <Box style={{margin:"auto", width:"100%", marginTop:"80px", marginRight:"15%"}}>
                <DataGrid style={{width:"100vw",height:"80vh"}} rows={props.data} columns={props.keys} autoPageSize={true} />
            </Box>
        </Box>
    )
}

export default ViewTable;