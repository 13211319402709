import React, {useState} from "react";
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import NavBar from './components/nav'
import ViewTable from "./components/table"
import LoginBox from "./components/login"
import Report from "./components/report"
import Axios from 'axios';

function App() {
const [data, setData] = useState(null);
const [loading, setLoading] = useState(false);
const [error, setError] = useState(null);
const [keys, setKeys] = useState([]);
const [logged, setLogged] = useState(false);
const [checked, setChecked] = useState(false);
const [pageTitle, setTitle] = useState("");
const os = require('os');

function refreshData(page) {
    let endpoint = null;
    let keysOut = null;
    setChecked(false);
    setTitle("");
    if(page === "workersToday") {
        setTitle("Worker Detail");
        endpoint = "getWorkerTodayData";
        keysOut = [
            {
                "field":"worker_id",
                "headerName": "ID Number",
                "flex": 1
            },
            {
                "field":"worker_name",
                "headerName":"Name",
                "flex": 1
            },
            {
                "field":"warehouse",
                "headerName":"WH",
                "flex": 1
            },
            {
                "field":"time",
                "headerName":"Shift Length",
                "flex": 1
            },
            {
                "field":"breaks",
                "headerName":"Break Length",
                "flex": 1
            }
        ]
    }
    else if(page === "workersHistoric") {
        setTitle("Historic Worker Detail");
        endpoint = "getWorkerData";
        keysOut = [
            {
                "field":"worker_id",
                "headerName": "ID Number",
                "flex": 1
            },
            {
                "field":"worker_name",
                "headerName":"Name",
                "flex": 1
            },
            {
                "field":"warehouse",
                "headerName":"WH",
                "flex": 1
            },
            {
                "field":"date",
                "headerName":"Date",
                "flex": 1
            },
            {
                "field":"time",
                "headerName":"Shift Length",
                "flex": 1
            },
            {
                "field":"breaks",
                "headerName":"Break Length",
                "flex": 1
            }
        ]
    }
    else if(page === "warehouseToday") {
        setTitle("Build Detail");
        endpoint = "getWarehouseTodayData";
        keysOut = [
            {
                "field":"customer",
                "headerName":"Customer",
                "flex": 1
            },
            {
                "field":"quantity",
                "headerName":"QTY",
                "flex": 1
            },
            {
                "field":"warehouse",
                "headerName":"WH",
                "flex": 1
            },
            {
                "field":"group_number",
                "headerName":"Group",
                "flex": 1
            }
        ]
    }
    else if(page === "warehouseHistoric") {
        setTitle("Historic Build Detail");
        endpoint = "getWarehouseData";
        keysOut = [
            {
                "field":"customer",
                "headerName":"Customer",
                "flex": 1
            },
            {
                "field":"build_date",
                "headerName":"Date",
                "flex": 1
            },

            {
                "field":"quantity",
                "headerName":"QTY",
                "flex": 1
            },
            {
                "field":"warehouse",
                "headerName":"WH",
                "flex": 1
            },
            {
                "field":"group_number",
                "headerName":"Group",
                "flex": 1
            }
        ]
    }
    else if(page === "unbuilt") {
        setTitle("Unbuilt Orders");
        endpoint = "getUnbuilt";
        keysOut = [
        {
            "field":"order_number",
            "headerName":"OrderNbr",
            "flex": 1
        },
        {
            "field":"customer",
            "headerName":"Vendor",
            "flex": 1
        },
        {
            "field":"date",
            "headerName":"Date",
            "flex": 1
        },
        {
            "field":"requested_date",
            "headerName":"Req Date",
            "flex": 1
        },
        {
            "field":"days_left",
            "headerName":"Due In",
            "flex": 1
        },
        {
            "field":"quantity",
            "headerName":"QTY",
            "flex": 1
        },
        {
            "field": "built",
            "headerName":"Built",
            "flex": 1
        },
        {
            "field": "remaining",
            "headerName":"Unbuilt",
            "flex": 1
        },
        {
            "field":"warehouse",
            "headerName":"WH",
            "flex": 1
        },
        {
            "field":"type",
            "headerName":"Type",
            "flex": 1
        }
    ]
    }
    else if(page === "workersBreaks") {
        setTitle("Break Report");
        endpoint = "getWorkersBreaks";
        keysOut = [
        {
            "field":"employee_id",
            "headerName":"Employee ID",
            "flex": 1
        },
        {
            "field":"worker_name",
            "headerName":"Name",
            "flex": 1
        },
        {
            "field":"warehouse",
            "headerName":"Warehouse",
            "flex":1
        },
        {
            "field":"group_number",
            "headerName":"Line",
            "flex":1
        },
        {
            "field":"date",
            "headerName":"Date",
            "flex": 1
        },
        {
            "field":"start",
            "headerName":"Break Start",
            "flex": 1
        },
        {
            "field":"length",
            "headerName":"Break Length",
            "flex": 1
        },
        {
            "field":"reason",
            "headerName":"Reason",
            "flex": 1
        }
    ]
    }
    else if(page === "efficiencyReport") {
        setTitle("Efficiency Report");
        endpoint = "getEfficiencyReport";
        keysOut = [
        {
            "field":"date",
            "headerName":"Date",
            "flex":1
        },
        {
            "field":"KY1",
            "headerName":"KY1",
            "flex":1
        },
        {
            "field":"KY2",
            "headerName":"KY2",
            "flex":1
        },
        {
            "field":"KY3",
            "headerName":"KY3",
            "flex":1
        },
        {
            "field":"KY4",
            "headerName":"KY4",
            "flex":1
        },
        {
            "field":"KY5",
            "headerName":"KY5",
            "flex":1
        },
        {
            "field":"KY6",
            "headerName":"KY6",
            "flex":1
        },
        {
            "field":"KY7",
            "headerName":"KY7",
            "flex":1
        },
        {
            "field":"KY8",
            "headerName":"KY8",
            "flex":1
        },
        {
            "field":"KY9",
            "headerName":"KY9",
            "flex":1
        },
        {
            "field":"KY10",
            "headerName":"KY10",
            "flex":1
        },
        {
            "field":"KY11",
            "headerName":"KY11",
            "flex":1
        },
        {
            "field":"KY12",
            "headerName":"KY12",
            "flex":1
        },
        {
            "field":"PA1",
            "headerName":"PA1",
            "flex":1
        },
        {
            "field":"PA2",
            "headerName":"PA2",
            "flex":1
        },
        {
            "field":"PA3",
            "headerName":"PA3",
            "flex":1
        },
        {
            "field":"PA4",
            "headerName":"PA4",
            "flex":1
        },
        {
            "field":"PA5",
            "headerName":"PA5",
            "flex":1
        },
        {
            "field":"PA 6",
            "headerName":"PA6",
            "flex":1
        }
    ]
    }
    else if(page === "report") {
        setTitle("Resend Reports");
        setData(page);
    }
    if(keysOut && endpoint) {
        getData(endpoint, keysOut);
    }
}


function getData(endpoint, keys) {
    setLoading(true);
    setError(null);
    setData(null);
    let api = "commerce-ims.com/v1/operations/";
    if(os.hostname().indexOf("local") > -1) {
        api = "mattk." + api;
    }
    const baseApiUrl = "https://api." + api;
    let url = baseApiUrl + endpoint;
    request(url).then(function(result) {
        setLoading(false);
        console.log(result);
        if(result.data) {
            result = result.data;
        }
        for(let i = 0; i < result.length; i++) {
            result[i].id = i + 1;
        }
        setKeys(keys);
        for(let i = 0; i < result.length; i++) {
            if(!result[i].breaks && endpoint.indexOf("worker") !== -1) {
                result[i].breaks = "00:00:00";
            }
            if(result[i].date)
                result[i].date = (new Date(result[i].date)).toISOString().substring(0, 10);
            if(result[i].requested_date)
                result[i].requested_date = result[i].requested_date.substring(0, 10);
        }
        setData(result);
    })
    .catch(function(err) {
        console.log(err);
        setError("Failed to pull data.");
        setLoading(false);
    })
}


function request(url) {
    return new Promise((resolve, reject) => {
        Axios.get(url, {headers: {'company_id':'Furhaven'}, mode: 'no-cors'}).then((result) => {resolve(result.data)}).catch((error) => {console.log(error); reject(error)});
    })
}

    return(
    <Box display="flex" style={{width:"100%"}}>
        <Box style={{width:"100%", position:"absolute"}}>
            <Box display="flex" style={{width:"100%", height:"50px", background:"#00703a", position:"absolute", zIndex:"15"}} > 
            <Box style={{margin:"auto", marginLeft:"100px", fontSize:"25px", color: "white"}}>{pageTitle}</Box>           
            <Box style={{marginLeft:"auto", marginRight:"20px", fontSize:"35px", fontWeight:"bold", color:"white"}}>Furhaven Admin Panel</Box>
            </Box>
            {!logged ? <LoginBox set={setLogged} /> :
                <NavBar setChecked={setChecked} checked={checked} loading={loading} style={{zIndex:3}} changePage={refreshData} />}
        </Box>
        <Box style={{width:"10%"}}></Box>
        {error ? 
            <Alert style={{position:"absolute", width:"20%", height:"fit-content", left:"500px", top:"1px"}} severity="error">{error}</Alert> 
        : null}
        {loading ? <Box style={{width:"100%", position:"absolute",marginTop:"60px"}} display="flex">
            <CircularProgress size={100} style={{color:'black', margin:"auto"}} />
            </Box> : null }
        {data && data !== "report" ? <ViewTable loading={loading} data={data} keys={keys} error={error} /> : null }
        {data === "report" ? <Report loading={loading} setError={setError} error={error} /> : null}
        {!data && logged && !loading ? <h2 style={{textAlign:"center", height:"fit-content", paddingTop:"50px", position:"absolute", width:"100%"}}>Select an option from the sidebar to view tables.</h2> : null}
        </Box>
    )

}



export default App;
